import React, { useState, useEffect } from 'react';
import logo  from '../Images/logo.png';
import {login} from "../Constants/endpoints"
import fetchHelper from "../Helper/Fetch"
import useAuthStore from '../Store/authStore';
import { toast } from 'react-toastify';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {  setToken, setUser, setLoading, setError } = useAuthStore(); 
  // const { token, user, isLoading, error, setToken, setUser, setLoading, setError } = useAuthStore();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        if (registration.waiting) {
          setShowUpdate(true);
        }
      },
    });
  }, []);

  const handleUpdate = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      });
    }
  };
  

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      // Save the event so it can be triggered later.
      console.log('beforeinstallprompt event fired'); // Log when event is triggered
      setDeferredPrompt(e);
      setShowInstallButton(true);  // Show the install button
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);  // Hide the install button after prompt
      });
    }
  };

  const Login = async (e) => {
    e.preventDefault()
    setLoading(true);
    let payload = {
      email: email,
      password: password
    }

    console.log("Payload: ".payload);
    const headers = {
     'Content-Type': 'application/json',
    };
    try {
      const data = await fetchHelper(login, 'POST', payload, headers);
      //handle response here
      if (data) {
        setToken(data.token);
        setUser(data.user);
        toast.success('Authentication successful');
        if(data.user.role == "enforcer"){
          window.location.href = '/search';
        }else if (data.user.role=="admin"){
          window.location.href="/dashboard"
        }else {
          window.location.href="monitor";
        }
        // Redirect to search page
        
      }
    } catch (error) {
      setError(error);
      toast.error('Authentication failed: ' + error.message);
      console.error('Error fetching data:', error);
    }finally{
      setLoading(false);
    }
  };

  return (
    <>
        <div className="bg-white min-h-screen flex flex-col items-center justify-center">
          {showInstallButton && (
            <button onClick={handleInstallClick} className='bg-green-500 rounded-lg p-1 text-white'>
              Install App
            </button>
          )}
          {showUpdate && (
              <>
                <p className='text-center text-green-500'>A new version is available!</p>
                <button onClick={handleUpdate} className='bg-green-500 rounded-lg p-1 text-white '>Update Now</button>
              </>
          )}
            <div className="bg-white opacity-90 p-8 rounded-lg shadow-md w-full">
                <div className="flex text-center mb-4">
                  <div className='flex-1'></div>
                  <div className='flex'>
                    <img src={logo} alt="SIRTS/SHF Logo" className="w-56" />
                  </div>
                  <div className='flex-1'></div>
                </div>
                <h2 className="text-2xl font-bold text-center mb-4">Login</h2>
                <form onSubmit={Login}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
                        <input type="email" id="email" className="border border-gray-400 rounded-md p-2 w-full" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Password</label>
                      <input type="password" id="password" className="border border-gray-400 rounded-md p-2 w-full" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md w-full">Login</button>
                </form>
            </div>
        </div>
    </>
    
  );
}

export default Login;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Page/Login';
import Profile from './Page/Profile';
import SearchPage from './Page/Search';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Enumeration from './Component/Enumeration';
import Dashboard from './Page/Dashboard';


function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/enumerate" element={<Enumeration />} />
          {/* <Route path="/dashboard" element={<Dashboard />} */}
        </Routes>
      </Router>   
    </div>
  );
}

export default App;

export const login = "/api/enforcer/login";
export const logout = "/api/enforcer/logout";
export const search = "/api/enforcer/search";
export const profile = "/api/enforcer/profile";
export const searchsticker = "/api/sticker";
export const validatesticker = "/api/validatesticker";
export const enumerate = "/api/enumerate";
export const vehicle_types = "/api/vehicle_type";
export const states ="/api/states";
export const lgs ="/api/lgs";
export const infraction = "/api/infraction-report";
export const closeshf = "/api/shf/"